import React from "react";
import tw from "twin.macro";
import moment from "moment";
import { Link } from "gatsby";

const PostPreviewWrapper = tw.div`w-full lg:w-8/12`
const PostHeader = tw.h2`mb-1 font-bold hover:underline text-gray-600 leading-9`
const PostTextPreview = tw.p`text-gray-500 mb-2`
const PostPreviewReadMoreButton = tw.span`text-green-600 hover:text-green-700 hover:underline rounded`

export const PostDate = tw.span`uppercase text-gray-500 font-bold`
export const PostTags = tw.span`uppercase text-gray-500`


export const BlogPostPreview = (props) => {
    return (
        <PostPreviewWrapper>
            <PostHeader><Link to={props.postInfo.path}>{props.postInfo.title}</Link></PostHeader>
            <PostTextPreview>
              {props.postInfo.description}<br /> 
              <Link to={props.postInfo.path}>
                <PostPreviewReadMoreButton>Read more</PostPreviewReadMoreButton>
              </Link>
            </PostTextPreview>
            <PostDate>{moment(props.postInfo.date).format('MMMM D, YYYY')}</PostDate><br />
            <PostTags>{props.postInfo.tags.join(" | ")}</PostTags>
        </PostPreviewWrapper>
    )
}
