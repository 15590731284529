import "../styles/global.css"
import "./layout.css"
import "@fontsource/source-sans-pro"
import "@fontsource/overpass-mono"
import "@fontsource/abhaya-libre"
import React from "react"
import tw from "twin.macro"
import { Link } from "gatsby"

const GridWrapper = tw.div`grid grid-cols-12 gap-4 bg-gray-100`
const BodyWrapper = tw.div`col-span-12 lg:col-span-10 text-gray-700 px-8 md:px-16 pb-8`
const FooterWrapper = tw.div``
const currentYear = new Date().getFullYear();

const SiteHeaderFirstName = tw.div`text-gray-100 text-2xl ml-2 font-sans uppercase`
const SiteHeaderLastName = tw.div`text-gray-100 text-4xl ml-2  font-bold font-sans uppercase leading-7`

const SidebarWrapper = tw.div`hidden lg:flex lg:flex-col lg:col-span-2 bg-gray-900 text-white py-8 px-4 border-r-8 border-green-500`
const SidebarContent = tw.div`sticky top-8`
const SidebarIntro = tw.div`p-2 m-0 mt-4 text-gray-300`
const SidebarLink = tw.div`hover:bg-green-600 text-white hover:font-bold text-lg p-4 rounded uppercase`
const SidebarDivider = tw.hr`my-4 text-gray-700`

const SmallDeviceNavLinks = tw.div`flex lg:hidden mt-8 p-2 w-full bg-gray-900 text-white rounded border-b-8 border-green-500`
const SmallDeviceNavLink = tw.div`p-2 px-4 mr-4 rounded bg-gray-800 hover:bg-green-600 text-white`

export default function Layout({ children }) {
  return (
      <main>
      <GridWrapper>

      <SidebarWrapper>
        <SidebarContent>
        <title>A blog by Darren Burns</title>
        <div style={{fontFamily: "Abhaya Libre"}}>
          <SiteHeaderFirstName>Darren</SiteHeaderFirstName>
          <SiteHeaderLastName>Burns</SiteHeaderLastName>
        </div>
        <SidebarIntro>
          Hey 👋 I'm Darren.<br />
          I'm a software engineer based in Edinburgh, Scotland
        </SidebarIntro>
        <SidebarDivider />
        <Link to="/"><SidebarLink>Posts</SidebarLink></Link>
        <a href="https://twitter.com/_darrenburns"><SidebarLink>Twitter</SidebarLink></a>
        <a href="https://github.com/darrenburns"><SidebarLink>GitHub</SidebarLink></a>
        </SidebarContent>
      </SidebarWrapper>

      <BodyWrapper>
        <SmallDeviceNavLinks>
          <Link to="/">
            <SmallDeviceNavLink>
              Posts
            </SmallDeviceNavLink>
          </Link>
          <a href="https://twitter.com/_darrenburns">
            <SmallDeviceNavLink>
              Twitter
            </SmallDeviceNavLink>
          </a>
          <a href="https://github.com/darrenburns">
            <SmallDeviceNavLink>
              GitHub
            </SmallDeviceNavLink>
          </a>
        </SmallDeviceNavLinks>
        {children}
        <FooterWrapper>
          <hr />
          Copyright © {currentYear} Darren Burns
        </FooterWrapper>
      </BodyWrapper>

      </GridWrapper>
      </main>
  )
}